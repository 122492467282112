<template>
    <div>
        <van-nav-bar
                title="收据列表"
                left-arrow
                fixed
                class="qjc-nav-bar"
                @click-left="$router.back()"
        />

        <div class="invoice" :style="{height: swipeHeight+'px'}">
            <div class="printBox" id="print">
                <div class="printHead">
                    <img src="../../../assets/images/logo.png" alt="logo">
                    <span>收據</span>
                    <div style="clear: both;"></div>
                </div>
                <div class="company printMsg">
                    <span>付款單位（個人）</span>
                    <p>{{title}}</p>
                </div>
                <div class="orderDate printMsg" style="width: 40%;">
                    <span>付款時間</span>
                    <p>{{pay_at}}</p>
                </div>
                <div class="orderID printMsg" style="width: 60%;">
                    <span>編號</span>
                    <p>{{o_sn}}</p>
                </div>
                <div style="clear: both;"></div>
                <table class="orderMsg">
                    <thead>
                    <tr>
                        <th style="width: 50%">項目</th>
                        <th style="width: 20%">數量</th>
                        <th style="width:30%;text-align: right">價格</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>健康大數據體檢——<br>
                            {{package}}
                        </td>
                        <td>1</td>
                        <td style="text-align: right">{{price}}港幣</td>
                    </tr>
                    </tbody>
                </table>
                <div class="printOff">
                    優惠碼折扣：<span>--港幣</span>
                </div>
                <div class="printTotal">
                    總計：<span>{{price}}港幣</span>
                </div>

                <div class="companyC">開票公司：米因智慧醫療有限公司 <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MEDIN AI & WISE MEDICAL
                    LIMITED
                </div>
            </div>
            <div class="downPrint" @click="printOut">下載收據</div>
        </div>


    </div>
</template>

<script>
    import Vue from 'vue'
    import {NavBar, Icon, List, Toast, Image} from 'vant'
    import {receipt} from '../../../js/axios'
    import html2canvas from 'html2canvas'
    import jspdf from 'jspdf'

    Vue.use(NavBar)
        .use(Toast);

    export default {
        name: "collection",
        data() {
            return {
                o_sn: "--",
                package: "--",
                pay_at: "--",
                price: "--",
                title: "--",
                swipeHeight:0
            }
        },
        computed: {},
        mounted() {

            this.swipeHeight = document.documentElement.clientHeight - 47;
            this.init();
        },
        beforeDestroy() {
            document.querySelector('body').removeAttribute('style')
        },
        methods: {
            init() {
                receipt({
                    act: 2,
                    id: this.$route.params.id,
                }).then(res => {
                    this.o_sn = res.data.o_sn;
                    this.package = res.data.package;
                    this.pay_at = res.data.pay_at;
                    this.price = res.data.price;
                    this.title = res.data.title;
                })
            },
            printOut () {
                let that = this;
                html2canvas(document.querySelector('#print'), {
                    allowTaint: true
                }).then(function (canvas) {
                        let contentWidth = canvas.width;
                        let contentHeight = canvas.height;
                        let pageHeight = contentWidth / 592.28 * 841.89;
                        let leftHeight = contentHeight;
                        let position = 0;
                        let imgWidth = 595.28;
                        let imgHeight = 592.28 / contentWidth * contentHeight;
                        let pageData = canvas.toDataURL('image/jpeg', 1.0);

                        let PDF = new jspdf('', 'pt', 'a4');
                        if (leftHeight < pageHeight) {
                            PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                        } else {
                            while (leftHeight > 0) {
                                PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
                                leftHeight -= pageHeight;
                                position -= 841.89;
                                if (leftHeight > 0) {
                                    PDF.addPage()
                                }
                            }
                        }
                        PDF.save('MedinReceipt_'+that.o_sn+ '.pdf')
                    }
                )
            }
        }
    }
</script>

<style scoped>
    .invoice {
        margin-top: 0.88rem;
        background: #F0F2F5;
        overflow: hidden;


    }
    .printBox {
        margin: 0.4rem 0.3rem;
        padding: 0.4rem;
        background: #ffffff;
    }
    .printHead {
        padding-bottom: 0.38rem;
        border-bottom: 0.01rem solid #DCDEE5;
    }

    .printHead img {
        display: block;
        float: left;
        width: 1.42rem;
        height: 0.36rem;
        padding-right: 0.12rem;
        border-right: 0.01rem solid #6681FA;
    }

    .printHead > span {
        display: block;
        float: left;
        margin-left: 0.13rem;
        line-height: 0.36rem;
        font-size: 0.32rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(64, 68, 77, 1)
    }

    .orderNum {
        display: block;
        float: right;
        height: 0.3rem;
        font-size: 0.12rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(119, 127, 143, 1);
        /*line-height:0.42rem;*/
        text-align: left;
    }

    .printMsg {
        display: inline-block;
        float: left;
        margin-top: 0.4rem;
        width: 100%;
        text-align: left;
        line-height: 0.42rem;
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 400;
    }

    .printMsg > span {
        color: #6883FB;
    }

    .printMsg > p {
        color: #40444D;
    }

    .orderMsg {
        width: 100%;
        margin-top: 0.7rem;
        margin-bottom: 0.39rem;
    }

    .orderMsg {
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 400;
        text-align: left;
    }

    .orderMsg thead th {
        color: #6883FB;
        padding-bottom: 0.2rem;
        font-weight: 400;
    }

    .orderMsg tbody td {
        border-top: 0.01rem #BABABA dashed;
        border-bottom: 0.01rem #BABABA dashed;
        color: #40444D;
        padding: 0.39rem 0;
    }

    .printOff {
        display: none;
        width: 100%;
        /*line-height: 0.84rem;*/
        font-size: 0.14rem;
        text-align: right;
        color: #777F8F;
        font-family: PingFang SC;
        font-weight: 400;
        margin-bottom: 0.3rem;
    }

    .printOff > span {
        text-indent: 0.18rem;
        color: #40444D;
    }

    .printTotal {
        font-family: PingFang SC;
        color: #40444D;
        font-size: 0.26rem;
        line-height: 0.18rem;
        font-weight: 500;
        text-align: right;
    }

    .printTotal > div {
        float: left;
        color: #777F8F;
        font-size: 0.2rem;
        font-weight: 400;
        text-align: left;
    }

    .printTotal > span {
        font-size: 0.32rem;
        color: #6883FB;
    }
    .companyC{
        margin-top: 1rem;
        text-align: right;
        font-size:0.2rem;
        font-family:PingFang SC;
        font-weight:400;
        color: #949FB3;
    }

    .downPrint{
        cursor: pointer;
        margin: 0.24rem auto;
        width:2.86rem;
        height:0.78rem;
        line-height:0.78rem;
        text-align: center;
        background:rgba(104,131,251,1);
        border-radius:0.04rem;
        color: white;
    }
</style>